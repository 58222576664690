import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import config, { device } from '../styles/config'

const Container = styled.div`
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 100%;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const Text = styled.div`
  font-family: 'GT America', sans-serif;

  h2 {
    font-family: 'GT America', sans-serif;
    font-size: 1rem;
    text-decoration: underline;
    font-weight: normal;
  }
  
  p {
    padding-bottom: 1rem;
  }

  a {
    text-decoration: underline;
  }
`

const PrivacyPolicy = ({ data: { datoCmsPrivacyPolicy: { text } } }) => {
  return (
    <Container>
      <Row>
        <Col xs={8} sm={4} smOffset={4}>
          <Text dangerouslySetInnerHTML={{ __html: text }}/>
        </Col>
      </Row>
    </Container>
  )
}

export const query = graphql`
  query PrivacyPolicyQuery {
    datoCmsPrivacyPolicy {
      text
    }
  }
`

export default PrivacyPolicy
